import { ReactNode, StateUpdater, useState } from 'preact/compat';

import EditVideoModal, { EditVideoModalProps } from './EditVideoModal';

interface UpdateMediaThumbnailModalTriggerProps extends EditVideoModalProps {
  render: (setIsOpen: StateUpdater<boolean>, isOpen: boolean) => ReactNode;
}

const EditVideoModalTrigger = ({ render, onClose, ...rest }: UpdateMediaThumbnailModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {render(setIsOpen, isOpen)}
      {isOpen && (
        <EditVideoModal
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          onClose={() => {
            onClose?.();
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditVideoModalTrigger;
