import { faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from 'Components/Authorize';
import LikeButton from 'Components/LikeButton';
import { useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from 'Shared/fetch';
import { logGAEvent } from 'Shared/ga';
import { trackMP } from 'Shared/mp';
import useFetch from 'use-http';
import { Permission } from 'zl-shared';

import { useSelector } from '@/redux/helper';
import { updateAlbumData } from '@/redux/slices/myAlbum/actions';

import { androidDevice, getDeviceType, iOSDevice } from '../../../helpers';
import store from '../../../redux/store';
import { updateHabitatAlbumData } from '../../../routes/habitat/components/Album/actions';
import { CopyLink, DeleteMediaButton, DownloadButton, FacebookShareLink, WebShare } from './helper';
import style from './style.scss';

const ShareButtons = ({ mediaId, data, slug, hashtags, userId, onDeleteSuccess }) => {
  const { url, videoURL, type = 'photo', habitatId, isLiked, usersLike, htmlURL, userId: ownerId, highlight } = data ?? {};
  const dispatch = useDispatch();

  const role = useSelector((state) => state.user.role);
  const userLoggedIn = useSelector((state) => state.user.logged);
  const [isHighlighted, setIsHighlighted] = useState(highlight);
  const { post: sharePost } = useFetch(API_BASE_URL, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });
  const { put } = useFetch(API_BASE_URL, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const logShare = async (platform) => {
    let habitatInfo = store.getState()?.habitat?.habitatInfo;
    if (type === 'trailer') {
      habitatInfo = store.getState()?.allHabitats?.filter((i) => i?._id === data?.habitat?.id)[0];
    }
    const shareLogObj = {
      userId: userId ?? 'guest',
      mediaId,
      mediaType: type,
      platform,
      slug,
      deviceType: getDeviceType(),
      applicationPath: document.location.pathname.startsWith('/h') ? 'habitat' : 'publicAlbum',
      habitatId: habitatInfo?._id ?? habitatId,
    };
    trackMP('shared-ugc', shareLogObj);
    await sharePost('/logs/share', shareLogObj);
  };

  const onHighlightClick = async () => {
    const newHighLight = !isHighlighted;
    setIsHighlighted(newHighLight);
    const payload = { id: mediaId, data: { ...data, highlight: newHighLight } };
    dispatch(updateHabitatAlbumData(payload));
    dispatch(updateAlbumData(payload));
    try {
      await put(`/videos/${mediaId}`, { highlight: newHighLight });
    } catch (e) {
      console.error('api error', e);
    }
  };

  return (
    <div className={style.shareButtons}>
      {/* Copy Link Button */}
      {htmlURL && <CopyLink link={htmlURL} onClick={() => logShare('shareLinkCopy')} />}
      {/* Download Button */}
      {!(androidDevice() || iOSDevice()) && (data.videoType === 'clip' || type === 'photo') && (
        <DownloadButton url={videoURL ?? url} userLoggedIn={userLoggedIn} data={data} />
      )}
      {/* Web Share API Button */}
      {(androidDevice() || iOSDevice()) && htmlURL && (
        <WebShare logShare={logShare} width={20} radius={20} htmlURL={htmlURL} photoURL={url} videoURL={videoURL} />
      )}

      {/* FB Share Button */}
      {htmlURL && <FacebookShareLink htmlURL={htmlURL} hashtags={hashtags} onClick={() => logShare('facebook')} />}

      {/* Like Button */}
      <LikeButton
        data={{ isLiked, likes: usersLike }}
        className={style.likeButton}
        onClick={() => logGAEvent('ugc', `react-liked-${type}`, slug)}
        mediaId={mediaId}
        type={type}
      />
      <div className={style.right}>
        {['admin', 'moderator'].includes(role) && type !== 'photo' && (
          <button type="button" className={style.delete} onClick={onHighlightClick}>
            <FontAwesomeIcon icon={isHighlighted ? faThumbsUpSolid : faThumbsUp} />
          </button>
        )}
        {/* Delete button */}
        {(ownerId === userId || hasPermission(Permission.Habitat.EditAlbumMediaHide)) && (
          <DeleteMediaButton mediaId={mediaId} type={type} onDeleteSuccess={onDeleteSuccess} />
        )}
      </div>
    </div>
  );
};

export default ShareButtons;
