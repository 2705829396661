import store from '@/redux/store';

import shareModalSlice from './index';

export const setShareModalMediaId = (mediaId: string) => {
  const state = store.getState();
  const { album } = state.habitat; // TODO: should find a way to avoid looking up the other slice of the state
  let data: any;
  let nextId: string | undefined;
  let prevId: string | undefined;

  const index = album.list.findIndex((item) => item?._id === mediaId);
  if (index >= 0) {
    data = album.list[index];
    nextId = index + 1 < album.list.length ? album.list[index + 1]._id : undefined;
    prevId = index - 1 >= 0 ? album.list[index - 1]._id : undefined;
  }
  return shareModalSlice.actions.setShareModalData({ mediaId, data, prevId, nextId });
};

export const { setShareModalData, closeShareModal, updateShareModalMediaData } = shareModalSlice.actions;
