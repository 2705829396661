import RoundButton from 'Components/RoundButton';
import { Box, Drop, RadioButtonGroup } from 'grommet';
import { useMemo, useRef, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import { useFetch } from 'use-http';

import { useIsMobileSize } from '../../hooks';
import { setSelectedCamera } from '../../routes/habitat/actions';
import style from './style.scss';
import switchCamera from './switchCamera.svg';

const CameraSelector = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const habitatId = useSelector((state) => state.habitat.habitatInfo._id);
  const selectedCamera = useSelector((state) => state.habitat.habitatInfo.selectedCamera);
  const cameras = useSelector((state) => state.habitat.habitatInfo.cameras);
  const fullScreen = useSelector((state) => state.mainStream.fullScreen);
  const cameraSelectorRef = useRef();
  const [showSelect, setShowSelect] = useState(false);
  const isMobileSize = useIsMobileSize();

  const availableCameras = useMemo(
    () => cameras.map(({ _id, cameraName }) => ({ label: cameraName, value: _id })),
    [cameras],
  );

  const { post } = useFetch(buildURL('/log'));

  const onChangeHandler = ({ target }) => {
    const cameraId = target.value;
    const camera = cameras.find(({ _id }) => _id === cameraId);
    dispatch(setSelectedCamera(camera));
  };

  const currentCamera = useMemo(() => {
    if (cameras.length < 2) {
      return null;
    }
    const currentCamera = cameras.find(({ _id }) => _id === selectedCamera._id);
    return currentCamera?._id || cameras[0]._id;
  }, [selectedCamera, cameras]);

  const clickHandler = () => {
    setShowSelect((current) => !current);
    post({
      name: 'CameraSwitch',
      userId,
      payload: { habitatId },
    });
  };

  const closeHandler = () => {
    setShowSelect(false);
  };

  if (cameras.length < 2) {
    return null;
  }

  return (
    <div ref={cameraSelectorRef} className={style.cameraButtonContainer}>
      <Box onClick={clickHandler} onMouseEnter={isMobileSize ? undefined : clickHandler}>
        <RoundButton width="28" backgroundColor="var(--hunterGreenMediumDark)" color="white" className={style.cameraButton}>
          <img src={switchCamera} alt="camera selector" className={style.switchCameraIcon} />
        </RoundButton>
      </Box>
      {cameraSelectorRef.current && showSelect && (
        <Drop
          plain
          responsive
          // FIXME: inline is now working on mobile, so we only use it when fullscreen.
          // Only desktop fullscreen has this component so we didn't check for mobile.
          inline={fullScreen}
          align={{ bottom: 'bottom' }}
          target={cameraSelectorRef.current}
          onClose={closeHandler}
          onClickOutside={closeHandler}
          onEsc={closeHandler}
          onMouseLeave={closeHandler}
          style={{ marginBottom: '30px', overflow: 'visible' }}
        >
          <Box align="center" pad="15px" className={style.cameraDrop}>
            <div className={style.extension} onClick={() => setShowSelect(!showSelect)} />
            <h3>Switch Camera:</h3>
            <RadioButtonGroup
              name="selectedStream"
              options={availableCameras}
              value={currentCamera}
              onChange={onChangeHandler}
              className={style.radioContainer}
            />
          </Box>
        </Drop>
      )}
    </div>
  );
};

export default CameraSelector;
