import EditVideoModal from './EditVideoModal';
import EditVideoModalTrigger from './EditVideoModalTrigger';

type CompositedModal = typeof EditVideoModal & {
  Trigger: typeof EditVideoModalTrigger;
};

const Modal = EditVideoModal;

(Modal as CompositedModal).Trigger = EditVideoModalTrigger;

export default Modal as CompositedModal;
